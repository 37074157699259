var request = require('request');
// Object to hold debounced emit functions for eac
function log(language, source,Snapshotname, logLine,totalread=1) {
  var options = {
    'method': 'POST',
    'url': 'https://us-central1-livechat-production.cloudfunctions.net/open/log',
    'headers': {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "streams": [
        {
          "stream": {
            "SOURCE": language,
            "FILENAME_PATH": source,
            "Snapshotname": Snapshotname,
            "totalread": totalread
          },
          "values": [
            [
              (new Date().getTime() * 1000000).toString(), // Current time in nanoseconds
              logLine
            ]
          ]
        }
      ]
    })
  };

  // request(options, function (error, response) {
  //   if (error) throw new Error(error);
  //   console.log(response.body);
  // });
}

module.exports = {
    log
  };;